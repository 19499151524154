import React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import Select from "react-select";


// Input feedback
export const InputFeedback = ({ error }) =>
  error ? <span className={classNames("input-feedback")}>{error}</span> : null;

// Checkbox input
export const Checkbox = ({
                    field: { name, value, onChange, onBlur },
                    form: { errors, touched, setFieldValue },
                    id,
                    label,
                    copy,
                    className,
                    ...props
                  }) => {

  return (
    <div className="checkbox-wrapper" key={id}>
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames("checkbox")}
      />
      <label key={id} htmlFor={id}><strong>{label}</strong>
        {(id.toString().toLowerCase() == "q4other" ? (
          null
        ) : copy)}
      </label>
      {(id.toString().toLowerCase() == "q4other" ? (
        <input type="text" id="other-text-checkbox" maxLength="25" placeholder="(Type response here)"/>
      ) : null)}
      {touched[name] && <InputFeedback error={errors[name]} />}
    </div>
  );
};

// Checkbox group
export class CheckboxGroup extends React.Component {

   handleChange = event => {
    const target = event.currentTarget;
    let valueArray = [...this.props.value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    this.props.onChange(this.props.id, valueArray);
  };

  handleBlur = () => {
    // take care of touched
    this.props.onBlur(this.props.id, true);
  };

  render() {
    const slick = {
      dots: false,
      infinite: false,
      speed: 500,
      adaptiveHeight: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerMode: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: true
          }
        }
      ]
    };
    const { value, error, touched, label, className, children, curQuestion } = this.props;

    const classes = classNames(
      "input-field",
      {
        "is-success": value || (!error && touched), // handle prefilled or user-filled
        "is-error": !!error && touched
      },
      className
    );

    return (
      <div className={classes}>
        <fieldset>
          <legend>{label} {touched && <InputFeedback error={error} />}</legend>
          <div className="">
            <Slider
              ref={c => (this.slider = c)}
              {...slick}
              key={curQuestion}
            >
            {React.Children.map(children, child => {
              return React.cloneElement(child, {
                field: {
                  value: value.includes(child.props.id),
                  onChange: this.handleChange,
                  onBlur: this.handleBlur
                }
              });
            })}
            </Slider>
          </div>
        </fieldset>
      </div>
    );
  }
}

// Radio input
export const RadioButton = ({
                       field: { name, value, onChange, onBlur },
                       id,
                       label,
                       className,
                       ...props
                     }) => {

  return (
    <div className="radio-wrapper col-12 col-sm-6">
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames("radio-button")}
        {...props}
      />
      <label htmlFor={id}>
        {label}
        {(id.toString().toLowerCase() == "other" ? (
          <input type="text" id="other-text" maxLength="25" placeholder="(Type response here)"/>
        ) : (undefined))}
      </label>
    </div>
  );
};

// Radio group
export const RadioButtonGroup = ({
                            value,
                            error,
                            touched,
                            id,
                            label,
                            className,
                            children
                          }) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched), // handle prefilled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <div className={classes}>
      <fieldset>
        <legend>{label} </legend>
        <div className="row">
          {children}
          {touched && <InputFeedback error={error} />}
        </div>
      </fieldset>
    </div>
  );
};

export const Label = ({ error, className, children, ...props }) => {
  return (
    <label className="label" {...props}>
      {children}
    </label>
  );
};

export const TextInput = ({ type, id, label, error, value, onChange, className, ...props }) => {
  const classes = classNames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    className
  );
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        className={`text-input ${error ? 'error' : ''}`}
        type={type}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  );
};

export class SelectField extends React.Component {

  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.name, true);
  };

  render() {
    let {name, multi, options, error, touched, value, placeholder} = this.props;

    return (
      <React.Fragment>
        {/*<label htmlFor={name}>Topics (select at least 3) </label>*/}
        <Select
          id={name}
          options={options}
          multi={multi}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={value}
          placeholder={placeholder}
          className={`${error ? 'error' : ''}`}
        />
        {!!error && touched && (
          <span className="error">{error.value ? error.value : error}</span>
        )}
      </React.Fragment>
    );
  }
}