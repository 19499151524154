import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ContextConsumer from '../components/Context'
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import Slider from "react-slick";
import {Checkbox, CheckboxGroup, RadioButton, RadioButtonGroup, SelectField} from '../components/FormTypes';
import axios from 'axios';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';
import countryData from '../data/countries';
import stateData from '../data/states';
import euData from '../data/eu';

const question1Schema = Yup.object().shape({
  question1: Yup.string()
    .required('Required')
    .min(0, 'Please select an option'),
});

const question2Schema = Yup.object().shape({
  question2: Yup.string()
    .required('Required')
    .min(0, 'Please select an option'),
});

const question3Schema = Yup.object().shape({
  question3: Yup.string()
    .required('Required')
    .min(0, 'Please select an option'),
});

const question4Schema = Yup.object().shape({
  question4: Yup.string()
    .required('Required')
    .min(0, 'Please select an option'),
});

const leadformSchema = Yup.object().shape({
  userFirstName: Yup.string()
    .required('First Name is required'),
  userLastName: Yup.string()
    .required('Last Name is required'),
  userTitle: Yup.string()
    .required('Job Title is required'),
  userEmail: Yup.string()
    .email('Please enter a valid E-mail address')
    .required('E-mail is required'),
  userPhone: Yup.string()
    .required('Phone is required'),
  companyName: Yup.string()
    .required('Company is required'),
  country: Yup.object().shape({
    label: Yup.string().required('Country is required'),
    value: Yup.string().required('Country is required'),
  }),
  state: Yup.string()
    .when('country', (country, schema) => {
      return (country.value === 'US' || country.value === 'CA' ) ? (country.value === 'US' ? schema.required('State is required') : schema.required('Province is required')) : undefined;
    }),
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      question1: "",
      question1Submitted: false,
      question2: "",
      question2Other: "",
      question2Submitted: false,
      question3: "",
      question3Submitted: false,
      question4: "",
      question4Other: "",
      question4Submitted: false,
      hotspot1: false,
      hotspot2: false,
      hotspot3: false,
      selectedResources: "",
      industryResources: "",
      selectedCapabilities: "",
      selectedCustomerStories: "",
      selectedDidYouKnows: "",
      showLearnMore: false
    }
    this._next = this._next.bind(this);
    this._previous = this._previous.bind(this);
    this._startOver = this._startOver.bind(this);
    this._toggleHotspot = this._toggleHotspot.bind(this);
    this._learnMore = this._learnMore.bind(this);
  }

  _next() {
    this.slider.slickNext();
    window.scrollTo(0, 0);
  }
  _previous() {
    this.slider.slickPrev();
    this.setState({hotspot1: false, hotspot2: false, hotspot3: false});
    window.scrollTo(0, 0);
  }

  _startOver() {
    this.slider.slickGoTo(1, true);
    window.scrollTo(0, 0);
    // If the user opened a hotspot, did not close the hotspot and advances,
    // The hotspot text will be on the screen before the hotspot appears if the user
    // restarts the navigator. this logic will set both hotspots to false when a user restarts the navigator 
    if(this.state.hotspot1) {
      this.setState({
        hotspot1: false
      })
    }
    if(this.state.hotspot2) {
      this.setState({
        hotspot2: false
      })
    }
  }

  _toggleHotspot(id) {
    if(id === 1) {
      this.setState({hotspot1: !this.state.hotspot1});
    }
    else if(id === 2) {
      this.setState({hotspot2: !this.state.hotspot2});
    }
    // update 2020 hotspot2 (Platform hotspot will be for step 3 now)
    else if(id === 3) {
      this.setState({hotspot2: !this.state.hotspot2});
    }
  }

  _learnMore() {
    this.setState({showLearnMore: !this.state.showLearnMore});
  }

  getDidYouKnows() {
    const { data } = this.props;
    const didYouKnows = data.didYouKnows.edges;
    const questions = data.questions.edges;

    let dyks = [];
    questions.map(question => {
      didYouKnows.map(dyk => {
        if(question.node.frontmatter.didYouKnow === dyk.node.frontmatter.title && dyk.node.frontmatter.title === "What Is a Platform?") {
          dyks.push(dyk.node.frontmatter);
        }
      })
    });

    return dyks;
  }

  getSelectedResources(selectedUseCases) {
    const { data } = this.props;
    const resources = data.resources.edges;

    let selectedResources = [];
    selectedUseCases.map(useCase => {
      useCase.node.frontmatter.resourcesList.map(item => {
        selectedResources.push(item.resource);
      })
    });
    selectedResources = [ ...new Set(selectedResources) ];

    let rr = [];
    resources.map(resource => {
      if(selectedResources.includes(resource.node.frontmatter.title)) {
        rr.push(resource);
      }
    });

    let rrFlat = [];
    rr.map(item => {
      rrFlat.push(item.node.frontmatter);
    });

    return rrFlat;
  }

  getIndustryResources() {
    const { data } = this.props;
    const resources = data.resources.edges;
    const industries = data.industries.edges;
    let {question2} = this.state;
    let q2 = question2.split(":");

    let selectedIndustries = [];
    industries.map(industry => {
      if(industry.node.frontmatter.title === q2[0]) {
        industry.node.frontmatter.resourcesList.map(resource => {
          selectedIndustries.push(resource);
        })
      }
    });

    let ir = [];
    resources.map(resource => {
        // 2020 Update: Only TEI Report and Digital Transformation Guidebook should be rendered to the PDF
        if(resource.node.frontmatter.title === 'Total Economic Impact Report' || resource.node.frontmatter.title === 'Digital Transformation Guidebook') {
          ir.push(resource)
        }
    });

    let irFlat = [];
    ir.map(item => {
      irFlat.push(item.node.frontmatter);
    });
    
    return irFlat;
  }

  getSelectedCapabilities(selectedUseCases) {
    const { data } = this.props;
    const capabilities = data.capabilities.edges;

    let selectedCapabilities = [];
    selectedUseCases.map(useCase => {
      useCase.node.frontmatter.capabilitiesList.map(item => {
        selectedCapabilities.push(item.capability);
      })
    });
    selectedCapabilities = [ ...new Set(selectedCapabilities) ];

    let sc = [];

    capabilities.map(capability => {
      if(selectedCapabilities.includes(capability.node.frontmatter.title)) {
        sc.push(capability);
      }
    });

    sc = sc.length > 4 ? sc.slice(0, 4) : sc;

    let scFlat = [];
    sc.map(item => {
      scFlat.push(item.node.frontmatter);
    });

    return scFlat;
  }

  getSelectedCustomerStories(selectedUseCases) {
    const { data } = this.props;
    const customerStories = data.customerStories.edges;

    let selectedCustomerStories = [];
    selectedUseCases.map(useCase => {
      useCase.node.frontmatter.customerstoriesList.map(item => {
        selectedCustomerStories.push(item.customerStory);
      })
    });
    selectedCustomerStories = [ ...new Set(selectedCustomerStories) ];

    let cs = [];

    customerStories.map(customerStory => {
      if(selectedCustomerStories.includes(customerStory.node.frontmatter.title)) {
        cs.push(customerStory);
      }
    });

    let customers = [];
    cs.map(customer => {
      customers.push(customer.node.frontmatter.customer);
    });

    let duplicates = [...new Set(customers.sort().filter((o, i) => o !== undefined && customers[i + 1] !== undefined && o === customers[i + 1]))];

    if(!Array.isArray(duplicates) || !duplicates.length) {
      let csFlat = [];
      cs.map(item => {
        csFlat.push(item.node.frontmatter);
      });

      return csFlat.slice(0, 4);
    }
    else {
      let csNoDups = [];
      csNoDups = duplicates.map(dup => cs.filter(obj => obj.node.frontmatter.customer !== dup));

      let csFlattenedDups = []
      csFlattenedDups = duplicates.map(dup => {
        return cs.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj[dup]).indexOf(obj[dup]) === pos;
        });
      });

      let csFinal = [];
      csNoDups[0].map(item => csFinal.push(item));
      csFlattenedDups[0].map(item => csFinal.push(item));

      let csFinalFlat = [];
      csFinal.map(item => {
        csFinalFlat.push(item.node.frontmatter);
      });
      
      return csFinalFlat.slice(0, 4);
    }
  }

  getDataForPDF() {
    const { data } = this.props;
    const useCases = data.useCases.edges;
    let {question1, question3, question4} = this.state;

    let q4 = [];
    question4.map(question => {
      let q = question.split(":");
      q[0].toString().toLowerCase() === "other" ? q4.push("Other") : q4.push(q[0]);
    })

    let selectedUseCases = [];
    useCases.map(useCase => {
      if(q4.includes(useCase.node.frontmatter.title) && question1 === useCase.node.frontmatter.sector && question3 === useCase.node.frontmatter.role) {
        selectedUseCases.push(useCase);
      }
    });

    let selectedResources = this.getSelectedResources(selectedUseCases);

    let industryResources = this.getIndustryResources();

    let selectedCapabilities = this.getSelectedCapabilities(selectedUseCases);

    let selectedCustomerStories = this.getSelectedCustomerStories(selectedUseCases);

    let selectedDidYouKnows = this.getDidYouKnows();

    this.setState({selectedResources, industryResources, selectedCapabilities, selectedCustomerStories, selectedDidYouKnows});
  }

  isEU(country) {
    if (country !== undefined) {
      if (euData.countries.some(item => (item.value === country)))
      {
        console.log(country + ' is in the EU');
        return true;
      }
      else {
        console.log(country + ' is NOT in the EU');
        return false;
      }
    }
  }

  render() {
    const { data } = this.props;
    let {hotspot1, hotspot2, hotspot3, question1, question2, question2Other, question3, question4, question4Other, selectedResources, industryResources, selectedCapabilities, selectedCustomerStories, selectedDidYouKnows, showLearnMore} = this.state;
    const intro = data.intro.edges[0].node.frontmatter;
    const questions = data.questions.edges;
    const sectors = data.sectors.edges;
    const industries = data.industries.edges;
    const roles = data.roles.edges;
    const customerStories = data.customerStories.edges;
    const resources = data.resources.edges;
    const capabilities = data.capabilities.edges;
    const useCases = data.useCases.edges;
    const didYouKnows = data.didYouKnows.edges;
    const leadform = data.leadform.edges[0].node.frontmatter;
    const thankyou = data.thankyou.edges[0].node.frontmatter;

    const q1 = questions.filter(x => x.node.frontmatter.questionNumber == 1)[0].node.frontmatter;
    const q2 = questions.filter(x => x.node.frontmatter.questionNumber == 2)[0].node.frontmatter;
    const q3 = questions.filter(x => x.node.frontmatter.questionNumber == 3)[0].node.frontmatter;
    const q4 = questions.filter(x => x.node.frontmatter.questionNumber == 4)[0].node.frontmatter;

    let question1answers = [];
    sectors.map(answer => {
      question1answers.push({id: answer.node.frontmatter.title, name: answer.node.frontmatter.title})
    });
    let question2answers = [];
    let hasOther = false;
    industries.map(answer => {
      if(answer.node.frontmatter.title.toString().toLowerCase() === "other") {
        hasOther = true;
      } else {
        question2answers.push({id: answer.node.frontmatter.title, name: answer.node.frontmatter.title})
      }
    });

    if (hasOther) {
      question2answers.push({id: "Other", name: "Other"})
    }

    let question3answers = [];
    roles.map(answer => {
      question3answers.push({id: answer.node.frontmatter.title, name: answer.node.frontmatter.title})
    });
    let otherQ4 = [];
    let question4answers = [];
    useCases.map(answer => {
      if (answer.node.frontmatter.sector == this.state.question1 && answer.node.frontmatter.role == this.state.question3) {
        if(answer.node.frontmatter.title.toString().toLowerCase() === "other") {
          otherQ4.push({id: answer.node.frontmatter.title, name: answer.node.frontmatter.title, copy: answer.node.frontmatter.copy})
        } else {
          question4answers.push({id: answer.node.frontmatter.title, name: answer.node.frontmatter.title, copy: answer.node.frontmatter.copy})
        }
      }
    });
    if (otherQ4.length >= 1) {
      question4answers.push({id: 'q4Other', name: 'Other', copy: 'q4Other'});
    }

    const slick = {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: false,
      arrows: false,
      swipeToSlide: false,
      draggable: false,
      swipe: false,
      className: "main-slider",
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    };
    
    return (
      <ContextConsumer>
        {({ data, set }) => (
          <section className={`section`}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <Slider
                    ref={c => (this.slider = c)}
                    {...slick}
                  >
                    <div id="intro">
                      <div className="row justify-content-center">
                        <div className="col-12 text-center">
                          <div className="intro">
                            <h1>{intro.title}</h1>
                            <p>{intro.copy}</p>
                            <button className="btn btn-primary" id="intro-button" onClick={() => {set({ bkgClass: 'quiz-1' }); this._next();}}>{intro.button}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="question-1">
                      <div className="row justify-content-center">
                        <div className="col text-center">
                          <div className="question-title-block">
                            <h6>{q1.title}</h6>
                            <h2>{q1.question}</h2>
                            <span>{q1.instructions}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Formik
                            initialValues={{
                              question1: ''
                            }}
                            validationSchema={question1Schema}
                            onSubmit={values => {
                              this.setState({question1: values.question1, question1Submitted: true});
                              this._next();
                              set({bkgClass: 'quiz-2'});
                            }}
                          >
                            {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
                              <Form>
                                <div className="form-body">
                                  <RadioButtonGroup
                                    id="question1"
                                    label=""
                                    value={values.question1}
                                    error={errors.question1}
                                    touched={touched.question1}
                                  >
                                    {question1answers.map(item => (
                                      <Field
                                        component={RadioButton}
                                        name="question1"
                                        id={item.id}
                                        label={item.name}
                                        key={item.id + "_" + item.name}
                                      />
                                    ))}
                                  </RadioButtonGroup>
                                </div>
                                <div className="form-footer">
                                  <button type="submit" id="question-1-next" className="btn btn-primary" disabled={!values.question1}>Next</button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    {/* <div id="question-2">
                      <div className="row justify-content-center">
                        <div className="col text-center">
                          <div className="question-title-block">
                            <h6>{q2.title}</h6>
                            <h2>{q2.question}</h2>
                            <span>{q2.instructions}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Formik
                            initialValues={{
                              question2: ''
                            }}
                            validationSchema={question2Schema}
                            onSubmit={values => {
                              if(values.question2 === "Other") {
                                let o = document.getElementById("other-text").value;
                                this.setState({question2: "Other", question2Other: o, question2Submitted: true});
                              }
                              else {
                                this.setState({question2: values.question2, question2Submitted: true});
                              }

                              this._next();
                              set({bkgClass: 'quiz-3'});
                            }}
                          >
                            {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
                              <Form>
                                <div className="form-body">
                                  <RadioButtonGroup
                                    id="question2"
                                    label=""
                                    value={values.question2}
                                    error={errors.question2}
                                    touched={touched.question2}
                                  >
                                    {question2answers.map(item => (
                                      <Field
                                        component={RadioButton}
                                        name="question2"
                                        id={item.id}
                                        label={item.name}
                                        key={item.id + "_" + item.name}
                                      />
                                    ))}
                                  </RadioButtonGroup>
                                </div>
                                <div className="form-footer">
                                  <button className="btn btn-secondary back" onClick={(e)=>{this._previous(); set({bkgClass: 'quiz-1'}); e.preventDefault();}}>Back</button>
                                  <button type="submit" id="question-2-next" className="btn btn-primary next" disabled={!values.question2}>Next</button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div> */}
                    <div id="question-3">
                      <div className="row justify-content-center">
                        <div className="col text-center">
                          <div className="question-title-block">
                            <h6>{q3.title}</h6>
                            <h2>{q3.question}</h2>
                            <span>{q3.instructions}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Formik
                            initialValues={{
                              question3: ''
                            }}
                            validationSchema={question3Schema}
                            onSubmit={values => {
                              this.setState({question3: values.question3, question3Submitted: true});
                              this._next();
                              set({bkgClass: 'quiz-4'});
                            }}
                          >
                            {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
                              <Form>
                                <div className="form-body">
                                  <RadioButtonGroup
                                    id="question3"
                                    label=""
                                    value={values.question3}
                                    error={errors.question3}
                                    touched={touched.question3}
                                  >
                                    {question3answers.map(item => (
                                      <Field
                                        component={RadioButton}
                                        name="question3"
                                        id={item.id}
                                        label={item.name}
                                        key={item.id + "_" + item.name}
                                      />
                                    ))}
                                  </RadioButtonGroup>
                                </div>
                                <div className="form-footer">
                                  <button className="btn btn-secondary back" onClick={(e)=>{this._previous(); set({bkgClass: 'quiz-1'}); e.preventDefault();}}>Back</button>
                                  <button type="submit" className="btn btn-primary next" disabled={!values.question3}>Next</button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    <div id="question-4">
                      <div className="row justify-content-center">
                        <div className="col text-center">
                          <div className="question-title-block">
                            <h6>{q4.title}</h6>
                            <h2>{q4.question}</h2>
                            <span>{q4.instructions}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Formik
                            initialValues={{
                              question4: '',
                              reset: question1 + " " + question3
                            }}
                            validationSchema={question4Schema}
                            onSubmit={values => {
                              let o = document.getElementById("other-text-checkbox").value;
                              let q4 = [];
                              values.question4.map(item => {
                                if (item == 'q4Other') {
                                  q4.push("Other");
                                }
                                else {
                                  q4.push(item);
                                }
                              });
                              this.setState({question4: q4, question4Other: o, question4Submitted: true});
                              this._next();
                              this.getDataForPDF();
                              set({bkgClass: 'lead-gen'});
                            }}
                            enableReinitialize={true}
                          >
                            {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
                              <Form>
                                <div className="form-body">
                                  <CheckboxGroup
                                    id="question4"
                                    label=""
                                    value={values.question4}
                                    error={errors.question4}
                                    touched={touched.question4}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    curQuestion={question1 + " " + question3}
                                  >
                                    {question4answers.map(item => (
                                      <Field
                                        component={Checkbox}
                                        name="question4"
                                        id={item.id}
                                        label={item.name}
                                        copy={item.copy}
                                        key={item.id + "_" + item.name}
                                      />
                                    ))}
                                  </CheckboxGroup>
                                </div>
                                <div className="form-footer">
                                  <button className="btn btn-secondary back" onClick={(e)=>{this._previous(); set({bkgClass: 'quiz-2'}); e.preventDefault();}}>Back</button>
                                  <button type="submit" className="btn btn-primary next" disabled={!values.question4}>Get Results</button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    <div id="lead-form">
                      <div className="row">
                        <div className="col-12 col-sm-5 col-lg-7">
                          <div className="question-title-block">
                            <h2 className="text-center text-sm-left">{leadform.title}</h2>
                            <p className="text-center text-sm-left">{leadform.copy}</p>
                            <img src={leadform.image.publicURL} alt="" className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-7 col-lg-5">
                          <div className="lead-form-container">
                            <h2>Sign up now to receive a PDF of your report.</h2>
                            <span>Please complete all fields.</span>
                            <Formik
                              initialValues={{
                                userFirstName: '',
                                userLastName: '',
                                userTitle: '',
                                userEmail: '',
                                userPhone: '',
                                companyName: '',
                                country: '',
                                state: '',
                                jpLegal: false
                              }}
                              validationSchema={leadformSchema}
                              onSubmit={values => {
                                // this.setState({question4: values.question4, question4Submitted: true});
                                axios.post('/api', {
                                  firstName: values.userFirstName,
                                  lastName: values.userLastName,
                                  title: values.userTitle,
                                  email: values.userEmail,
                                  phone: values.userPhone,
                                  companyName: values.companyName,
                                  country: values.country.value,
                                  state: values.state.value,
                                  question1: question1,
                                  question2: question2,
                                  question2Other: question2Other,
                                  question3: question3,
                                  question4: question4,
                                  question4Other: question4Other,
                                  selectedResources: selectedResources,
                                  industryResources: industryResources,
                                  selectedCapabilities: selectedCapabilities,
                                  selectedCustomerStories: selectedCustomerStories,
                                  selectedDidYouKnows: selectedDidYouKnows
                                })
                                .then(function (response) {
                                  console.log(response);
                                  let blob = b64toBlob(response.data, 'application/pdf');
                                  saveAs(blob, "sfgov.pdf");
                                  set({bkgClass: 'start-over'});
                                })
                                .catch(function (error) {
                                  alert("Something went wrong. Please try again later.");
                                  console.log(error);
                                });
                                this._next();
                              }}
                            >
                              {({ setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                                <Form>
                                  <div className="form-body">
                                    <Field
                                      id="userFirstName"
                                      type="text"
                                      label="First Name"
                                      placeholder="First name"
                                      error={touched.userFirstName && errors.userFirstName}
                                      value={values.userFirstName}
                                      className={`${errors.userFirstName ? 'error' : ''}`}
                                    />
                                    <ErrorMessage name="userFirstName">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                    <Field
                                      id="userLastName"
                                      type="text"
                                      label="Last Name"
                                      placeholder="Last name"
                                      error={touched.userLastName && errors.userLastName}
                                      value={values.userLastName}
                                      className={`${errors.userLastName ? 'error' : ''}`}
                                    />
                                    <ErrorMessage name="userLastName">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                    <Field
                                      id="userTitle"
                                      type="text"
                                      label="Last Name"
                                      placeholder="Job title"
                                      error={touched.userTitle && errors.userTitle}
                                      value={values.userTitle}
                                      className={`${errors.userTitle ? 'error' : ''}`}
                                    />
                                    <ErrorMessage name="userTitle">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                    <Field
                                      id="userEmail"
                                      type="email"
                                      label="Email"
                                      placeholder="Email"
                                      error={touched.userEmail && errors.userEmail}
                                      value={values.userEmail}
                                      className={`${errors.userEmail ? 'error' : ''}`}
                                    />
                                    <ErrorMessage name="userEmail">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                    <Field
                                      id="userPhone"
                                      type="tel"
                                      label="userPhone"
                                      placeholder="Phone"
                                      error={touched.userPhone && errors.userPhone}
                                      value={values.userPhone}
                                      className={`${errors.userPhone ? 'error' : ''}`}
                                    />
                                    <ErrorMessage name="userPhone">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                    <Field
                                      id="companyName"
                                      type="text"
                                      label="companyName"
                                      placeholder="Company"
                                      error={touched.companyName && errors.companyName}
                                      value={values.companyName}
                                      className={`${errors.companyName ? 'error' : ''}`}
                                    />
                                    <ErrorMessage name="companyName">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                    <SelectField
                                      value={values.country}
                                      onChange={setFieldValue}
                                      onBlur={setFieldTouched}
                                      error={errors.country}
                                      touched={touched.country}
                                      name="country"
                                      multi={false}
                                      options={countryData}
                                      placeholder="Country"
                                    />
                                    {values.country.value === 'US' || values.country.value === 'CA' ? (
                                      <SelectField
                                        value={values.state}
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        error={errors.state}
                                        touched={touched.state}
                                        name="state"
                                        multi={false}
                                        options={values.country.value === "CA" ? stateData.CA : stateData.US}
                                        placeholder={values.country.value === "CA" ? "Province" : "State"}
                                      />
                                    ) : undefined}
                                    {values.country.value === 'JP' ? (
                                      <span className="legal">
                                        <Field
                                          component={Checkbox}
                                          name="jpLegal"
                                          id="jpLegal"
                                          label="jpLegal"
                                        /> I agree to the Privacy Statement and to the handling of my personal information. In particular, I consent to the transfer of my personal information to other countries, including the United States, for the purpose of hosting and processing the information as set forth in the <a href="https://www.salesforce.com/jp/company/privacy/" target="_blank">Privacy Statement</a>
                                        <span className="learn-more"><a onClick={this._learnMore}>Learn More</a></span>
                                        <span style={{display: showLearnMore ? 'block' : 'none'}}>I understand that these countries may not have the same data protection laws as the country from which I provide my personal information. For more information, click <a href="https://www.salesforce.com/jp/company/privacy/" target="_blank">here</a>.</span>
                                      </span>
                                    ) : (
                                      <span className="legal">By registering, you confirm that you agree to the storing and processing of your personal data by Salesforce as described in the <a href="https://www.salesforce.com/company/privacy/" target="_blank">Privacy Statement</a>.</span>
                                    )}
                                  </div>
                                  <div className="form-footer justify-content-between">
                                    <button className="lead-form-back-link" id="lead-form-back-link" onClick={(e)=>{this._previous(); set({bkgClass: 'quiz-4'}); e.preventDefault();}}><i className="fa fa-angle-left"></i> Back</button>
                                    <button
                                      type="submit"
                                      id="download-report"
                                      className="btn btn-primary"
                                      disabled={values.country.value === 'JP' && !values.jpLegal}
                                    >Download Report</button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="pdf-splash">
                      <div className="row justify-content-center">
                        <div className="col text-center">
                          <div className="question-title-block start-over">
                            <h2>{thankyou.title}</h2>
                            <p>{thankyou.copy}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col text-center">
                          <button className="restart" id="restart-navigator" onClick={(e) => {this._startOver(); set({bkgClass: 'quiz-1'}); e.preventDefault();}}><i className="fa fa-repeat"></i>Restart Navigator</button>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="hotspot-section">
              <div id="hotspot-1">
                {didYouKnows.map((dyk, index)=>(
                  (dyk.node.frontmatter.title == q1.didYouKnow) ? (
                    <React.Fragment key={`dyk-${index}`}>
                      <div className={`hotspot-dialog ${hotspot1 ? "show" : ""}`}>
                        <img src={dyk.node.frontmatter.imagePdf.publicURL} alt="" className="mobile-img"/>
                        <button onClick={()=>this._toggleHotspot(1)}>
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <h3>{dyk.node.frontmatter.title}</h3>
                        <p>{dyk.node.frontmatter.description}</p>
                        <a href={dyk.node.frontmatter.longLink} id={`dyk-hs1-learn-more-${index}`} target="_blank" rel="noopener noreferrer">Learn More <i className="fa fa-angle-right"></i></a>
                      </div>
                      <div className="hotspot-1-img-container">
                        <img src={dyk.node.frontmatter.image.publicURL} id={`dyk-hs1-toggle-${index}`} alt="" onClick={()=>this._toggleHotspot(1)}/>
                      </div>
                    </React.Fragment>
                  ) : (undefined)
                ))}
              </div>
              <div id="hotspot-2">
                {didYouKnows.map((dyk, index)=>(
                  (dyk.node.frontmatter.title == q3.didYouKnow) ? (
                    <React.Fragment key={`dyk-${index}`}>
                      <div className={`hotspot-dialog ${hotspot2 ? "show" : ""}`}>
                        <img src={dyk.node.frontmatter.imagePdf.publicURL} alt="" className="mobile-img"/>
                        <button onClick={()=>this._toggleHotspot(2)}>
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <h3>{dyk.node.frontmatter.title}</h3>
                        <p>{dyk.node.frontmatter.description}</p>
                        <a href={dyk.node.frontmatter.longLink} id={`dyk-hs2-learn-more-${index}`} target="_blank" rel="noopener noreferrer">Learn More <i className="fa fa-angle-right"></i></a>
                      </div>
                      <div className="hotspot-2-img-container">
                        <img src={dyk.node.frontmatter.image.publicURL} id={`dyk-hs2-toggle-${index}`} alt="" onClick={()=>this._toggleHotspot(2)}/>
                      </div>
                    </React.Fragment>
                  ) : (undefined)
                ))}
              </div>
              {/* <div id="hotspot-3">
                {didYouKnows.map((dyk, index)=>(
                  (dyk.node.frontmatter.title == q3.didYouKnow) ? (
                    <React.Fragment key={`dyk-${index}`}>
                      <div className={`hotspot-dialog ${hotspot3 ? "show" : ""}`}>
                        <img src={dyk.node.frontmatter.imagePdf.publicURL} alt="" className="mobile-img"/>
                        <button onClick={()=>this._toggleHotspot(3)}>
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                        <h3>{dyk.node.frontmatter.title}</h3>
                        <p>{dyk.node.frontmatter.description}</p>
                        <a href={dyk.node.frontmatter.longLink} id={`dyk-hs3-learn-more-${index}`} target="_blank" rel="noopener noreferrer">Learn More <i className="fa fa-angle-right"></i></a>
                      </div>
                      <div className="hotspot-3-img-container">
                        <img src={dyk.node.frontmatter.image.publicURL} id={`dyk-hs3-toggle-${index}`}  alt="" onClick={()=>this._toggleHotspot(3)}/>
                      </div>
                    </React.Fragment>
                  ) : (undefined)
                ))}
              </div> */}
            </div>
          </section>
        )}
      </ContextConsumer>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  {
    intro: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "intro-page" } }}
    ) {
      edges {
        node {
          frontmatter {
            title
            copy
            button
          }
        }
      }
    }
    questions: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___questionNumber] },
      filter: { frontmatter: { templateKey: { eq: "questions" } }}
    ) {
      edges {
        node {
          frontmatter {
						questionNumber
            title
            question
            instructions
            didYouKnow
          }
        }
      }
    }
    sectors: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "sectors" } }}
    ) {
      edges {
        node {
          frontmatter {
						title
          }
        }
      }
    }
    industries: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "industries" } }}
    ) {
      edges {
        node {
          frontmatter {
						title
            headline
            description
            link
            image {
              publicURL
            }
            resourcesList {
              resource
            }
          }
        }
      }
    }
    roles: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "roles" } }}
    ) {
      edges {
        node {
          frontmatter {
						title
          }
        }
      }
    }
    customerStories: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___customer] },
      filter: { frontmatter: { templateKey: { eq: "customer-stories" } }}
    ) {
      edges {
        node {
          frontmatter {
						title
            customer
            story
            link
          }
        }
      }
    }
    resources: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "resources" } }}
    ) {
      edges {
        node {
          frontmatter {
						title
            description
            link
            image {
              publicURL
            }
          }
        }
      }
    }
    capabilities: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "capabilities" } }}
    ) {
      edges {
        node {
          frontmatter {
						title
            description
            link
            image {
              publicURL
            }
          }
        }
      }
    }
    useCases: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___name] },
      filter: { frontmatter: { templateKey: { eq: "use-cases" } }}
    ) {
      edges {
        node {
          frontmatter {
            title
            copy
            sector
            role
            resourcesList {
              resource
            }
            capabilitiesList {
              capability
            }
            customerstoriesList {
              customerStory
            }
          }
        }
      }
    }
    didYouKnows: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "did-you-knows" } }}
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            link
            longLink
            image {
              publicURL
            }
            imagePdf {
              publicURL
            }
          }
        }
      }
    }
    leadform: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "leadform" } }}
    ) {
      edges {
        node {
          frontmatter {
            title
            copy
            image {
              publicURL
            }
          }
        }
      }
    }
    thankyou: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] },
      filter: { frontmatter: { templateKey: { eq: "thankyou" } }}
    ) {
      edges {
        node {
          frontmatter {
            title
            copy
          }
        }
      }
    }
  }
`
